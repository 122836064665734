/* You can add global styles to this file, and also import other style files */

@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import './assets/styles/variables';
@import "../node_modules/compass-mixins/lib/compass";
@import "../node_modules/compass-mixins/lib/animate";;
@import "../node_modules/mdi/scss/materialdesignicons";
@import "../node_modules/flag-icon-css/sass/flag-icon";

@import "~typicons.font/src/font/typicons.css";
@import "./assets/styles/components/loaders/loaders";

@import "./assets/styles/fonts";
@import"./assets/styles/mixins/text";
@import "./assets/styles/mixins/functions";
@import "./assets/styles/mixins/background";
@import "./assets/styles/mixins/animation";
@import "./assets/styles/mixins/buttons";
@import "./assets/styles/mixins/misc";
@import "./assets/styles/mixins/color-functions";
@import "./assets/styles/mixins/cards";
@import "./assets/styles/mixins/blockqoute";


@import "./assets/styles/components/forms";
@import "./assets/styles/components/icons";
@import "./assets/styles/components/tables";
@import "./assets/styles/components/buttons";
@import "./assets/styles/components/cards";
@import "./assets/styles/components/checkbox-radio";
@import "./assets/styles/typography";
@import "./assets/styles/components/preview";
@import "./assets/styles/components/lists";
@import "./assets/styles/components/bootstrap-progress";
@import "./assets/styles/components/dropdowns";
@import "./assets/styles/components/nav";
@import "./assets/styles/components/timeline";
@import "./assets/styles/reset";
@import "./assets/styles/components/rating";
@import "./assets/styles/components/plugin-overrides/chartjs";
@import "./assets/styles/responsive";
@import "./assets/styles/misc";
@import "./assets/styles/utilities";
@import "./assets/styles/auth";
@import "./assets/styles/demo";
@import "./assets/styles/misc";
@import "./assets/styles/layouts";
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

html,
body {
  font-family: Roboto !important;
}

// overwriting bootstrap-> _dropdown.scss class
.custom-dropdown {
  .dropdown-menu {
    display: block;
    max-height: 300px;
    overflow-y: auto;
  }
}

.wrapper-1 {
  padding: 0.9rem 0.0rem;
}

.card-body-spacing{  
    padding: 0.88rem 0.81rem;
}


.table-border{
  border: 1px solid #dee2e6;
  margin-top: 20px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #007DCC;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #007DCC;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .table thead th {
    color: #454545;
  }

  .table td {
    color: #747474;
  }

  .btn-outline-primary {
    color: #007DCC !important;
    border-color: #007DCC !important;
  }

  .btn-outline-primary:hover {
    color: #ffffff !important;
    background-color: #007DCC !important;
    border-color: #007DCC !important;
  }

  .show > .btn-outline-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #007DCC !important;
  }

  .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: #007DCC !important;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  max-width: 300px !important;
  margin-bottom: 9px !important;
}

.badge-success{
  background-color: goldenrod;
}